import { Container} from "react-bootstrap";

import PROJ from "../assets/img/PROJ.png";
import yell from "../assets/img/YellingCat.mp4";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  return (
    <section className="project">
      <Container>
        <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeInBottomRight" : ""}>
                <h2>$YELLCAT TOKENOMICS!</h2>

                <img src={PROJ} alt="PROJ"/> 
              </div>}
        </TrackVisibility>

              <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeInBottomLeft" : ""}>
                <h2 className="pt-44" id="projects">Yelling Cat</h2>
              </div>}
        </TrackVisibility>

      <div className="ratio ratio-16x9">
      <video controls>
  <source src={yell} type="video/mp4" />

</video>
              </div>          <br /><br />

              

      </Container>
    </section>
    
  )
}


